.contact {
  background-color: #400072;
  color: white;
  font-family: "Exo 2", sans-serif;
  font-size: calc(5px + 2vmin);
}

.social {
  height: 8vmin;
  pointer-events: none;
  padding:20px;
}

.contact-info {
  display: flex;
  flex-direction: row;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-inner {
  background-color: #400072;
  color: white;
  font-family: "Exo 2", sans-serif;
  font-size: calc(5px + 2vmin);
  padding: 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}